import React from "react";

import s from "./projects.module.scss";

function ProjectsSection() {
    return (
        <section id="projects" className={s.projectsSection}>
            <h1>Projects</h1>
        </section>
    );
}

export default ProjectsSection;