import React from "react";

import s from "./footer.module.scss";

function Footer() {
    return (
        <footer id="contact" className={s.contactSection}>
            <h1>Footer</h1>
        </footer>
    );
}

export default Footer;