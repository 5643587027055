import React from 'react';
import { Link } from 'react-scroll';
import s from './nav.module.scss';

import { useTranslation } from 'react-i18next';


function Nav() {
  const { t } = useTranslation();

  return (
    <nav className={s.nav}>
      <div className={`${s.navContainer}`}>
            <Link to="header" smooth duration={500}>{t('home')}</Link>
            <Link to="about" smooth duration={500}>{t('about')}</Link>
            <Link to="projects" smooth duration={500}>{t('projects')}</Link>
            <Link to="contact" smooth duration={500}>{t('contact')}</Link>
      </div>
    </nav>
  );
}

export default Nav;
