import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './about.module.scss';

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className={s.aboutSection}>
      <h2>{t('about_section_title')}</h2>
      <p>{t('welcome_message')}</p>
    </section>
  );
};

export default About;
