import React from 'react';

import s from './header.module.scss';

import gearSVG from '../../utils/ressources/icons/gear.svg';
import instaSVG from '../../utils/ressources/icons/instaIcon.svg';
import linkedinSVG from '../../utils/ressources/icons/LinkedInIcon.svg';


function HeaderSection() {
    return (
        <section id='header'>
            <div className={s.bottomHeader}></div>
            <div className={s.param}>
                <img src={gearSVG} alt="gear" className={s.paramIMG}></img>
                <div className={s.paramList}>
                    <p>test</p>
                    <p>test</p>
                </div>
            </div>
            <div className={s.headerPanel}>
                
                <div className={s.headerPres}>
                    <h1>Arthur</h1>
                    <h2>ZACHARY</h2>
                </div>
                <div className={s.socialLinks}>
                    <a href="https://www.linkedin.com/in/arthur-zachary/" alt="#" className={s.socialLinkBox}>
                        <img src={linkedinSVG} alt="LinkedIn" className={s.socialLinkIMG}></img>
                        <div className={s.linkShadow}></div>
                    </a>
                    <a href="https://www.instagram.com/_k0ola/" alt="#" className={s.socialLinkBox}>
                        <img src={instaSVG} alt="Instagram" className={s.socialLinkIMG}></img>
                        <div className={s.linkShadow}></div>
                    </a>
                    {/* <a href="#" alt="" className={s.socialLinkBox}>
                        
                        <div className={s.linkShadow}></div>
                    </a>
                    <a href="#" alt="" className={s.socialLinkBox}>
                        
                        <div className={s.linkShadow}></div>
                    </a> */}
                </div>
            </div>
        </section>
    );
}

export default HeaderSection;