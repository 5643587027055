import React from "react";
import "./utils/styles/root.scss";

import HeaderSection from './components/Header/HeaderSection';
import AboutSection from './components/About/AboutSection';
import ProjectsSection from './components/Projects/ProjectsSection';
import Footer from './components/Footer/Footer';
// import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import Nav from './components/Nav/Nav';

const App = () => {
  return (
        <div className="app">
          <Nav />
          <HeaderSection />
          <AboutSection />
          <ProjectsSection />
          <Footer />
        </div>
      )
};

export default App;
